import React from 'react';

import { ScPage } from '../components/page/styled';
import { MainLayout } from '../layouts/main-layout';
import { Benefits } from '../sections/ProviderCycling/Benefits';
import { CustomersHero } from '../sections/ProviderCycling/CustomersHero';
import { FlexiVoucherInfo } from '../sections/ProviderCycling/FlexiVoucherInfo';
import { ProviderCyclingHero } from '../sections/ProviderCycling/ProviderCyclingHero';
import {SEO} from "../components/Seo/Seo";

const ProviderCycling = () => {
	return (
		<MainLayout>
			<ScPage>
				<ProviderCyclingHero />
				<Benefits />
				<CustomersHero />
				<FlexiVoucherInfo />
			</ScPage>
		</MainLayout>
	);
};

export default ProviderCycling;

export const Head = () => (
	<SEO
		title="Gogeta's Cycle to Work Retailer Network | Low 3% Commission"
		description="Sign up as a Gogeta retailer partner and offer bikes via the Cycle to Work scheme. Enjoy low 3% commission while helping employees save on their new bikes."
	/>
)
